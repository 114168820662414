import { X as IconX } from 'react-feather';

import { Box, IconButton, Modal, ModalProps, Stack, SxProps, Theme, useTheme } from '@mui/material';

import Button from '~/components/atoms/Button';
import Heading from '~/components/atoms/Heading';
import { mergeSx } from '~/utils/mui';

interface InfoModalProps extends Omit<ModalProps, 'children'> {
    title?: string;
    handleCloseModal: () => void;
    sections: Array<{
        title?: string;
        content: string | JSX.Element;
        button?: JSX.Element;
        containerSx?: SxProps<Theme>;
    }>;
    sx?: SxProps<Theme>;
    modalSx?: SxProps<Theme>;
    showTopCloseButton?: boolean;
    showBottomCloseButton?: boolean;
}

export const InfoModal = ({
    title,
    sections,
    handleCloseModal,
    showBottomCloseButton,
    showTopCloseButton,
    sx,
    modalSx,
    ...props
}: InfoModalProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Modal
            keepMounted
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...props}
            sx={mergeSx(
                {
                    backdropFilter: 'blur(3px)',
                    [theme.breakpoints.up('md')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                },
                modalSx,
            )}
        >
            <Stack
                sx={mergeSx(
                    {
                        p: 4,
                        bgcolor: 'background.paper',
                        height: 'fit-content',
                        [theme.breakpoints.up('sm')]: {
                            p: 8,
                        },
                        [theme.breakpoints.up('md')]: {
                            p: 8,
                            maxWidth: '500px',
                            borderRadius: theme.radius.allLarge,
                        },
                        pt: 2,
                    },
                    sx,
                )}
            >
                <Stack
                    sx={{
                        mb: 4,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {title && (
                        <Heading component="h1" variant="h5">
                            {title}
                        </Heading>
                    )}
                    {showTopCloseButton && (
                        <IconButton
                            aria-label="lukk dialog"
                            sx={{
                                height: '48px',
                                width: '48px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={handleCloseModal}
                        >
                            <IconX size={24} color={theme.palette.text.primary} />
                        </IconButton>
                    )}
                </Stack>
                {sections.map((section, index) => (
                    <Box key={`infomodal-section-${index}`}>
                        <Stack sx={{ mb: 2 }}>
                            {section.title && (
                                <Heading variant="h6" component="h2">
                                    {section.title}
                                </Heading>
                            )}
                        </Stack>
                        <Stack sx={mergeSx({ mb: section.button ? 4 : 8 }, section.containerSx)}>
                            {section.content}
                        </Stack>
                        {section.button && <Stack sx={{ mb: 8 }}>{section.button}</Stack>}
                    </Box>
                ))}
                {showBottomCloseButton && (
                    <Stack sx={{ alignItems: 'flex-end' }}>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Lukk
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Modal>
    );
};
